import type { UserPromotionCubit } from '@breakoutlearning/firebase-repository/cubits/UserPromotionCubit'
import type { UserPromotion } from '@breakoutlearning/firebase-repository/models/UserPromotion'
import classNames from 'classnames'
import { InfoIcon } from 'components/icons/Info'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

export const UserPromotions = observer(function UserPromotionsDialog({
  cubit,
}: {
  cubit: UserPromotionCubit
}) {
  const { t } = useTranslation()
  const promotions = cubit.userPromotions.models
  return (
    <div className="flex h-full flex-col">
      <h1 className="text-headline-large">{t('profile.promotions')}</h1>
      <div className="text-body-large mb-3 text-core-on-tertiary">
        {t('profile.promotions_description')}
      </div>
      <div className="mt-6 flex h-full w-full flex-1 flex-col gap-2">
        {promotions.length === 0 && (
          <div className="flex h-full flex-1 flex-col items-center justify-center rounded-3xl bg-surface p-3">
            <InfoIcon className="fill-fixed-accent-color" size={32} />
            <div className="text-headline-small mt-1 text-core-on-tertiary">
              {t('profile.no_promotions_found')}
            </div>
            <div className="text-body-large mt-2 text-center text-core-on-tertiary">
              {t('profile.no_promotions')}
            </div>
          </div>
        )}
        {promotions.map((promotion) => (
          <UserPromotionRow
            key={promotion.id}
            cubit={cubit}
            promotion={promotion}
          />
        ))}
      </div>
    </div>
  )
})

const UserPromotionRow = observer(function UserPromotionRow({
  cubit,
  promotion,
}: {
  cubit: UserPromotionCubit
  promotion: UserPromotion
}) {
  const { t } = useTranslation()
  const data = promotion.data
  const redemptions = cubit.userPromotionRedemptions.get(promotion.id) ?? []
  const consumed = redemptions.length
  const expirationDateTime = DateTime.fromJSDate(data.expirationDate)
  const isActive = promotion.isActive
  return (
    <div className="flex flex-row  items-center justify-between rounded-2xl bg-surface p-5">
      <div>
        <div
          className={classNames('text-label-small uppercase', {
            'text-core-error': !isActive,
            'text-core-success': isActive,
          })}
        >
          {isActive ? t('profile.active') : t('profile.inactive')}
        </div>
        <div className="text-label-medium">{data.promotionName}</div>
        <div className="text-body-small text-on-surface-var">
          {t('profile.expires_at', {
            expirationDate: expirationDateTime.toLocaleString(
              DateTime.DATE_FULL
            ),
          })}
        </div>
      </div>
      <div className="text-center">
        <div className="text-title-large text-on-surface">
          {consumed}/{data.consumableCount}
        </div>
        <div className="text-body-small text-grey-text">
          {t('profile.consumed')}
        </div>
      </div>
    </div>
  )
})
