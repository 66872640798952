import type { InstructorSlideDeckCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorSlideDeckCubit'
import { AssignmentGroupingType } from '@breakoutlearning/firebase-repository/models/SectionAssignment'
import { AssignmentType } from '@breakoutlearning/firebase-repository/types'
import { Spinner } from 'components/Spinner'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutSelect } from 'components/design-system/BreakoutSelect'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { Dialog, InlineDialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { Save } from 'components/icons/Save'
import { useBreakoutUser } from 'hooks/profile'
import { useRootStore } from 'hooks/rootStore'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { BreakoutDateTimeInput } from 'components/design-system/BreakoutDateTimeInput'
import { dateSchema } from 'util/schema-date'
import { FormLabel } from 'components/design-system/form/FormLabel'
import { useDialogs } from 'hooks/dialogs'
import { AddSectionDialog } from '../classes/AddSectionDialog'
import { PlusIcon } from 'components/icons/Plus'

const getSchema = (
  t: ReturnType<typeof useTranslation>['t'],
  minExpiration: DateTime,
  maxExpiration: DateTime
) =>
  z.object({
    assignmentType: z
      .preprocess((val) => {
        return Number(val)
      }, z.nativeEnum(AssignmentType))
      .default(AssignmentType.studentLed),
    sectionId: z.string({ message: t('instructor_library.section_missing') }),
    catalogId: z.string(),
    assignedAt: dateSchema({
      required: t('instructor_assignment.start_date_required'),
    }),
    expiresAt: dateSchema({
      required: t('instructor_library.deadline_required'),
      min: {
        date: minExpiration,
        message: t('instructor_library.deadline_must_be_future'),
      },
      max: {
        date: maxExpiration,
        message: t('instructor_library.deadline_too_far_out'),
      },
    }),
    groupingType: z.preprocess((val) => {
      return Number(val)
    }, z.nativeEnum(AssignmentGroupingType)),
    groupingSize: z.coerce
      .number()
      .min(2, { message: t('instructor_library.grouping_size_invalid') })
      .max(6, { message: t('instructor_library.grouping_size_invalid') })
      .optional(),
  })

type FormValues = z.infer<ReturnType<typeof getSchema>>

export const AssignClassDialog = observer(function AssignClassDialog({
  cubit,
  onCreate,
}: {
  cubit: InstructorSlideDeckCubit
  onCreate?: () => void
}) {
  const store = useRootStore()
  const user = useBreakoutUser()
  const { showDialog } = useDialogs()

  const [showConfirmDialog, setShowConfirmDialog] = useState(false)

  const [minScheduleDate, maxScheduleDate] = useMemo(() => {
    const now = DateTime.now()

    // set min date to start of tomorrow
    const min = now.plus({ days: 1 }).startOf('day')
    const max = now.plus({ days: 365 })

    return [min, max]
  }, [])

  const { t } = useTranslation()
  const rootStore = useRootStore()

  const queryParams = store.router.queryParams

  // if section id is present in the query params, only show that section
  const sectionIdParam = useMemo(
    () => queryParams?.sectionId?.toString(),
    [queryParams]
  )

  const schema = useMemo(
    () => getSchema(t, minScheduleDate, maxScheduleDate),
    [maxScheduleDate, minScheduleDate, t]
  )

  const {
    control,
    setValue,
    trigger,
    formState: { isSubmitting },
    setError,
    handleSubmit,
    getValues,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    defaultValues: {
      catalogId: queryParams?.catalogId?.toString() || undefined,
      assignmentType: AssignmentType.studentLed,
      groupingType: AssignmentGroupingType.manual,
      sectionId: sectionIdParam,
      assignedAt: DateTime.now(),
      groupingSize: 4,
    },
  })

  const groupingType = useWatch({ control, name: 'groupingType' })
  const expiresAt = useWatch({ control, name: 'expiresAt' })

  const validate = async () => {
    const data = getValues()
    const expiresAtMinusOneDay = data.expiresAt?.minus({ days: 1 })

    if (data.assignedAt >= expiresAtMinusOneDay) {
      setError('assignedAt', {
        message: t('instructor_assignment.start_date_after_deadline'),
      })
      return false
    }

    const isValid = await trigger()

    return isValid
  }

  const onSubmit = useCallback(
    async (data: FormValues) => {
      const assignmentId = await cubit.createSectionAssignment({
        slideDeckId: cubit.slideDeckId,
        ...data,
      })

      if (assignmentId) {
        store.navigateTo('instructorClassAssignment', {
          id: data.sectionId,
          assignmentId: assignmentId,
        })
      }
      onCreate?.()
    },
    [cubit, onCreate, store]
  )

  const currentExpiresAt = useWatch({
    control,
    name: 'expiresAt',
  }) as DateTime | null

  return (
    <Dialog size="md" testId="assign-class-dialog">
      <DialogCloseButton />
      <div className="text-headline-large mb-6">
        {t('instructor_library.experience_settings')}
      </div>
      <form
        className="flex flex-col space-y-3"
        onSubmit={(e) => e.preventDefault()}
      >
        {user?.isAdmin && (
          // if user is an admin show the assignmentType select
          <Controller
            control={control}
            name="assignmentType"
            render={({ field, fieldState }) => (
              <BreakoutSelect
                {...field}
                error={fieldState.error}
                required
                kind="secondary"
                label={t('instructor_library.assignment_type')}
                name="assignmentType"
                options={[
                  {
                    value: AssignmentType.studentLed,
                    label: t('instructor_library.assignment_type_student_led'),
                  },
                  {
                    value: AssignmentType.instructorLed,
                    label: t(
                      'instructor_library.assignment_type_instructor_led'
                    ),
                  },
                ]}
              />
            )}
          />
        )}

        {cubit.sections.isLoading && <Spinner className="mx-auto" />}
        {cubit.sections.isLoaded && (
          <Controller
            control={control}
            name="sectionId"
            render={({ field, fieldState }) => (
              <BreakoutSelect
                {...field}
                testId="section-select"
                error={fieldState.error}
                required
                kind="secondary"
                label={t('instructor_library.select_class')}
                labelComponent={
                  <div className="flex w-full flex-row items-center justify-between">
                    <FormLabel
                      label={t('instructor_library.select_class')}
                      name={field.name}
                      required={true}
                    />
                    {!sectionIdParam && !user.isTA && (
                      <label className="text-body-medium w-full px-4 text-on-surface-var">
                        <button
                          className="text-body-medium  w-full whitespace-nowrap text-on-surface-var"
                          data-testid="add-new-section-button"
                          onClick={() => {
                            showDialog(({ remove }) => {
                              return (
                                <AddSectionDialog
                                  createSection={cubit.createSection}
                                  onCreate={(sectionId) => {
                                    if (!sectionId) return

                                    rootStore.updateQueryParams({ sectionId })

                                    remove()

                                    setValue('sectionId', sectionId)
                                  }}
                                  instructorUserId={
                                    rootStore.impersonatedUserId
                                  }
                                  redeemPromotions={cubit.redeemPromotions}
                                />
                              )
                            })
                          }}
                        >
                          <div className="flex items-center justify-end">
                            <PlusIcon
                              size={14}
                              className="mr-0.5 stroke-fixed-accent-color"
                              pathClassName="stroke-fixed-accent-color"
                              pathStrokeWidth={3.5}
                            />
                            {t('instructor_library.add_new_class')}
                          </div>
                        </button>
                      </label>
                    )}
                  </div>
                }
                name="sectionId"
                placeholder={
                  !cubit.sections.length
                    ? t('instructor_library.no_classes_found')
                    : t('instructor_library.select_class')
                }
                options={cubit.sections.models.map((section) => ({
                  value: section.id,
                  label: `${section.data.className}: ${section.data.sectionName}`,
                }))}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="assignedAt"
          render={({ field, fieldState }) => {
            return (
              <BreakoutDateTimeInput
                {...field}
                error={fieldState.error}
                type="datetime-local"
                label={t('instructor_assignment.start_date')}
                required
                kind="secondary"
                id="assignment-start-time"
                name="assignedAt"
                hideNowButton
                max={currentExpiresAt?.minus({ days: 1 })}
                value={field.value}
                onChange={field.onChange}
              />
            )
          }}
        />
        <Controller
          control={control}
          name="expiresAt"
          render={({ field, fieldState }) => {
            return (
              <BreakoutDateTimeInput
                {...field}
                error={fieldState.error}
                type="datetime-local"
                label={t('instructor_assignment.assignment_deadline')}
                required
                kind="secondary"
                id="meeting-time"
                name="expiresAt"
                hideNowButton
                min={minScheduleDate}
                max={maxScheduleDate}
                value={field.value}
                onChange={field.onChange}
              />
            )
          }}
        />
        <Controller
          control={control}
          name="groupingType"
          render={({ field }) => (
            <BreakoutSelect
              {...field}
              label={t('instructor_library.student_grouping')}
              name="groupingType"
              kind="secondary"
              onChange={(value) => {
                field.onChange(value)
                if (value === AssignmentGroupingType.manual) {
                  setValue('groupingSize', undefined)
                } else {
                  setValue('groupingSize', 4)
                }
              }}
              options={[
                {
                  value: AssignmentGroupingType.manual,
                  label: t('instructor_library.students_self_grouping'),
                },
                {
                  value: AssignmentGroupingType.automaticRandom,
                  label: t('instructor_library.automatic_randomized_grouping'),
                },
              ]}
            />
          )}
        />

        {groupingType === AssignmentGroupingType.automaticRandom && (
          <Controller
            control={control}
            name="groupingSize"
            render={({ field, fieldState }) => (
              <BreakoutTextInput
                {...field}
                kind="secondary"
                error={fieldState.error}
                type="number"
                label={t('instructor_library.desired_group_size')}
                name="groupingSize"
                min="2"
                max="6"
              />
            )}
          />
        )}

        <div className="mt-4">
          <BreakoutButton
            size="large"
            type="button"
            className="mt-2"
            onClick={async (e) => {
              e.preventDefault()

              const isValid = await validate()
              if (!isValid) return
              setShowConfirmDialog(true)
            }}
            data-testid="assign-class-button"
            icon={<Save size="18" />}
            fullWidth
            loading={isSubmitting}
          >
            {t('instructor_library.assign_to_class')}
          </BreakoutButton>
          <ConfirmDialog
            showConfirmDialog={showConfirmDialog}
            setShowConfirmDialog={setShowConfirmDialog}
            groupingType={groupingType}
            isSaving={isSubmitting}
            expiresAt={expiresAt?.toString()}
            onSubmit={handleSubmit(onSubmit)}
          />
        </div>
      </form>
    </Dialog>
  )
})

function ConfirmDialog({
  showConfirmDialog,
  setShowConfirmDialog,
  groupingType,
  isSaving,
  expiresAt,
  onSubmit,
}: {
  showConfirmDialog: boolean
  groupingType: AssignmentGroupingType
  isSaving: boolean
  expiresAt: string
  setShowConfirmDialog: (show: boolean) => void
  onSubmit: () => Promise<void>
}) {
  const { t } = useTranslation()

  const groupingDate = expiresAt
    ? DateTime.fromISO(expiresAt).minus({
        milliseconds:
          DateTime.fromISO(expiresAt).diffNow('milliseconds').milliseconds *
          0.2,
      })
    : undefined

  return (
    <InlineDialog
      open={showConfirmDialog}
      onDismiss={() => setShowConfirmDialog(false)}
      size="xs"
      className="!bg-core-tertiary"
      testId="assign-class-confirm-dialog"
    >
      <DialogCloseButton
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setShowConfirmDialog(false)
          return false
        }}
      />
      {groupingType === AssignmentGroupingType.automaticRandom ? (
        <div className="mb-10 mt-5">
          <div className="text-headline-large mb-5">
            {t('instructor_library.automatic_grouping')}
          </div>
          <div className="text-body-medium mb-5">
            {t('instructor_library.confirm_auto_grouping1')}
            <br />
            {groupingDate ? (
              <strong>{groupingDate.toFormat('MMM dd, yyyy (hh:mm a)')}</strong>
            ) : null}
          </div>
          <div className="text-body-medium mb-5">
            {t('instructor_library.confirm_auto_grouping2')}
          </div>
          <div>
            <BreakoutButton
              size="large"
              fullWidth
              onClick={onSubmit}
              loading={isSaving}
              data-testid="assign-class-confirm-auto"
            >
              {t('instructor.confirm')}
            </BreakoutButton>
          </div>
        </div>
      ) : (
        <div className="mb-10 mt-5">
          <div className="text-headline-large mb-5">
            {t('instructor_library.students_self_grouping')}
          </div>
          <div className="text-body-medium mb-5">
            {t('instructor_library.confirm_self_grouping1')}
          </div>
          <div className="text-body-medium mb-5">
            {t('instructor_library.confirm_self_grouping2')}
          </div>
          <div>
            <BreakoutButton
              size="large"
              fullWidth
              loading={isSaving}
              data-testid="assign-class-confirm"
              onClick={onSubmit}
            >
              {t('instructor.confirm')}
            </BreakoutButton>
          </div>
        </div>
      )}
    </InlineDialog>
  )
}
