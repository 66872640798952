import { InstructorSlideDeckCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorSlideDeckCubit'
import { SlideDeckMaterialType } from '@breakoutlearning/firebase-repository/types'
import { SlideProjector } from 'components/SlideProjector'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { ChevronLeft } from 'components/icons/ChevronLeft'
import { EyeOpen } from 'components/icons/EyeOpen'
import { PlusIcon } from 'components/icons/Plus'
import { useRepository } from 'hooks/auth'
import { useCubitBuilder } from 'hooks/cubits'
import { useDialogs } from 'hooks/dialogs'
import { useRootStore } from 'hooks/rootStore'
import { observer } from 'mobx-react-lite'
import {
  Actions,
  Contents,
  Header,
  Headline,
  MainPane,
} from 'pages/layout/TwoColumn'
import { ShowExperienceDialog } from 'pages/student/assignment/dialogs/ShowExperienceDialog'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { AssignClassDialog } from './AssignClassDialog'
import { type SlideQuestion } from '@breakoutlearning/firebase-repository/models/SlideQuestion'
import { iconFromSlideQuestion } from 'util/iconFromSlideQuestion'
import { CourseMaterialListItem } from 'components/slide-deck-material-components/CourseMaterialListItem'
import { ImpersonationInfo } from 'components/ImpersonationInfo'

export const InstructorSlideDeckPageDeprecating = observer(
  function InstructorSlideDeckPageDeprecating({
    slideDeckId,
  }: {
    slideDeckId: string
  }) {
    const { t } = useTranslation()
    const store = useRootStore()
    const repository = useRepository()
    const cubit = useCubitBuilder(
      () => new InstructorSlideDeckCubit(repository, slideDeckId),
      [repository, slideDeckId]
    )
    const { showDialog } = useDialogs()
    const queryParams = store.router.queryParams

    return (
      <MainPane>
        <Header>
          <Headline
            className="text-display-medium font-display min-w-[35%] cursor-pointer truncate"
            onClick={() => {
              if (
                typeof queryParams?.teachingPlanId === 'string' &&
                typeof queryParams?.catalogId === 'string'
              ) {
                store.navigateToTeachingPlanPage(
                  queryParams?.catalogId,
                  queryParams?.teachingPlanId
                )
                return
              }
              store.navigateTo('instructorLibrary', undefined, {
                sectionId: queryParams?.sectionId,
              })
            }}
          >
            <div className="flex flex-row items-center">
              <div className="pr-1">
                <ChevronLeft size={24} />
              </div>
              {cubit.slideDeck.data.slideDeckName}
              <ImpersonationInfo />
            </div>
          </Headline>
          <Actions>
            <BreakoutButton
              kind="secondary"
              size="large"
              icon={<EyeOpen size={14} />}
              onClick={() => {
                showDialog(() => (
                  <ShowExperienceDialog slideDeckId={cubit.slideDeckId} />
                ))
              }}
            >
              {t('instructor_library.preview')}
            </BreakoutButton>

            {/* only show the assign class dialog if catalogId is set and the deck is not archived */}
            {!!queryParams?.catalogId && !cubit.slideDeck.isArchived && (
              <BreakoutButton
                kind="primary"
                size="large"
                icon={<PlusIcon size={14} />}
                onClick={() => {
                  showDialog(({ remove }) => (
                    <AssignClassDialog cubit={cubit} onCreate={remove} />
                  ))
                }}
              >
                {t('instructor_library.assign_to_class')}
              </BreakoutButton>
            )}
          </Actions>
        </Header>

        <Contents className="h-full md:overflow-hidden">
          <PageContent cubit={cubit} />
        </Contents>
      </MainPane>
    )
  }
)

const PageContent = observer(function PageContent({
  cubit,
}: {
  cubit: InstructorSlideDeckCubit
}) {
  return (
    <div className="flex h-full flex-col gap-5 md:flex-row">
      <div className="flex h-full flex-none flex-col rounded-2xl bg-surface md:w-[300px]">
        <div className="flex-1 overflow-auto p-5">
          <img
            src={cubit.slideDeck.data.slideDeckImageURL}
            alt=""
            className="mx-auto mb-5 max-w-[50px]"
          />

          <div className="text-headline-medium px-5">
            {cubit.slideDeck.data.slideDeckName}
          </div>

          <div className="text-body-medium mb-3 px-5 text-grey-text">
            Version {cubit.slideDeck.data.slideDeckVersion}
          </div>

          <div className="text-body-medium px-5 text-grey-text md:overflow-hidden">
            {cubit.slideDeck.data.slideDeckDescription}
          </div>
        </div>
        <div className="flex-none p-5">
          <div className="text-body-large flex h-13 flex-row items-center justify-center gap-1 rounded-2xl bg-core-tertiary p-2 text-center text-core-on-tertiary">
            <Trans
              i18nKey="instructor_library.price_student"
              defaults="<bold>{{count}}</bold>"
              values={{ count: cubit.slideDeck.data.slideDeckPrice }}
              components={{
                bold: <span className="text-title-medium text-core-primary" />,
              }}
            />
          </div>
        </div>
      </div>
      <div className="h-full flex-1 rounded-2xl bg-surface">
        <PageMainPanel cubit={cubit} />
      </div>
    </div>
  )
})

const PageMainPanel = observer(function PageMainPanel({
  cubit,
}: {
  cubit: InstructorSlideDeckCubit
}) {
  const [tab, setTab] = useState(0)
  const Component = getComponent(tab, cubit)
  return (
    <div className="flex h-full flex-col p-5">
      <div className="space-x-3">
        <BreakoutButton
          onClick={() => setTab(0)}
          kind={tab === 0 ? 'tertiary' : 'secondary'}
        >
          Polls
        </BreakoutButton>
        <BreakoutButton
          onClick={() => setTab(1)}
          kind={tab === 1 ? 'tertiary' : 'secondary'}
        >
          Quiz
        </BreakoutButton>
        <BreakoutButton
          onClick={() => setTab(2)}
          kind={tab === 2 ? 'tertiary' : 'secondary'}
        >
          Course Materials
        </BreakoutButton>
      </div>
      <SlideProjector className="mt-5 flex-1" index={tab}>
        {Component}
      </SlideProjector>
    </div>
  )
})

function getComponent(tab: number, cubit: InstructorSlideDeckCubit) {
  switch (tab) {
    case 0:
      return <QuestionsView filter={(q) => q.isPoll} cubit={cubit} />
    case 1:
      return <QuestionsView filter={(q) => q.isQuiz} cubit={cubit} />
    case 2:
      return <MaterialsView cubit={cubit} />
    default:
      return <QuestionsView filter={(q) => q.isPoll} cubit={cubit} />
  }
}

const QuestionsView = observer(function QuestionsView({
  cubit,
  filter,
}: {
  cubit: InstructorSlideDeckCubit
  filter: (q: SlideQuestion) => boolean
}) {
  const questions = cubit.questionsSorted.filter(filter)

  return (
    <div className="h-full md:overflow-auto">
      {questions.map((question) => {
        const LeadingIcon = iconFromSlideQuestion(question)
        return (
          <div
            key={question.id}
            className="text-title-medium mb-2 flex flex-row gap-4.5 rounded-2xl bg-core-tertiary p-5"
          >
            <LeadingIcon size={24} className="min-h-6 min-w-6" />
            <span>{question.data.question}</span>
          </div>
        )
      })}
    </div>
  )
})

const MaterialsView = observer(function MaterialsView({
  cubit,
}: {
  cubit: InstructorSlideDeckCubit
}) {
  const materialsWithoutFeatured = cubit.materials.models.filter(
    (material) =>
      material.data.materialType !== SlideDeckMaterialType.featuredLarge
  )

  return (
    <div className="h-full md:overflow-auto">
      {materialsWithoutFeatured.map((material) => (
        <div
          className="mb-2 flex flex-row items-center justify-between rounded-2xl bg-core-tertiary p-2 py-6"
          key={material.id}
        >
          <CourseMaterialListItem
            className="w-full justify-between px-7"
            material={material}
            slideDeckId={cubit.slideDeckId}
            slideDeckName={cubit.slideDeck.data.slideDeckName}
          />
        </div>
      ))}
    </div>
  )
})
