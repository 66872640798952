import { observer } from 'mobx-react-lite'
import { InstructorLibraryPageOriginal } from './InstructorLibraryPageOriginal'
import { InstructorLibraryPageWithTeachingPlans } from './InstructorLibraryPageWithTeachingPlans'
import { useRepository } from 'hooks/auth'
import { UserProfileRole } from '@breakoutlearning/firebase-repository/types'
import { useBreakoutUser } from 'hooks/profile'

export const InstructorLibraryPage = observer(function InstructorLibraryPage() {
  const repository = useRepository()
  const { role } = useBreakoutUser()
  const isAdmin = role === UserProfileRole.admin
  const flags = repository.featureFlags
  if (!flags.isLoaded) return null
  const useVersionWithTeachingPlans =
    flags.data.exploreWithTeachingPlans || isAdmin

  if (useVersionWithTeachingPlans) {
    return <InstructorLibraryPageWithTeachingPlans />
  } else {
    return <InstructorLibraryPageOriginal />
  }
})
