import { observer } from 'mobx-react-lite'
import { useRepository } from 'hooks/auth'
import { InstructorSlideDeckPageDeprecating } from './InstructorSlideDeckPageDeprecated'
import { InstructorSlideDeckPage } from './InstructorSlideDeckPage'
import { useBreakoutUser } from 'hooks/profile'
import { UserProfileRole } from '@breakoutlearning/firebase-repository/types'

export const InstructorSlideDeckPageWrapper = observer(
  function InstructorSlideDeckPageWrapper({
    slideDeckId,
  }: {
    slideDeckId: string
  }) {
    const repository = useRepository()
    const { role } = useBreakoutUser()
    const isAdmin = role === UserProfileRole.admin
    const flags = repository.featureFlags
    if (!flags.isLoaded) return null
    const useVersionWithTeachingPlans =
      flags.data.exploreWithTeachingPlans || isAdmin

    if (useVersionWithTeachingPlans) {
      return <InstructorSlideDeckPage slideDeckId={slideDeckId} />
    } else {
      return <InstructorSlideDeckPageDeprecating slideDeckId={slideDeckId} />
    }
  }
)
