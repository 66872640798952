import { zodResolver } from '@hookform/resolvers/zod'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutDateTimeInput } from 'components/design-system/BreakoutDateTimeInput'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { useDialogs } from 'hooks/dialogs'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { dateSchema } from 'util/schema-date'
import { z } from 'zod'

const getSchema = (
  t: ReturnType<typeof useTranslation>['t'],
  minDateOverride?: DateTime
) => {
  const minDatePlus1Min = (
    minDateOverride ? minDateOverride : DateTime.now()
  ).minus({ minutes: 1 })
  const maxDatePlus1Year = DateTime.now().plus({ years: 1 })
  const tScoped = (key: string) => t(`instructor_assignment.${key}`)
  return z.object({
    // no min date
    assignedAt: dateSchema({
      required: tScoped('start_date_required'),
    }),
    expiresAt: dateSchema({
      required: tScoped('deadline_required'),
      min: {
        date: minDatePlus1Min,
        message: minDateOverride
          ? tScoped('deadline_must_be_future')
          : tScoped('deadline_below_min'),
      },
      max: {
        date: maxDatePlus1Year,
        message: tScoped('deadline_above_max'),
      },
    }),
  })
}

export const ScheduleAssignmentDialog = observer(
  function ScheduleAssignmentDialog({
    expiresAt,
    assignedAt,
    hasBeenScheduled,
    onSave,
  }: {
    expiresAt?: DateTime
    assignedAt?: DateTime
    hasBeenScheduled: boolean
    onSave: (params: {
      assignedAt: DateTime
      expiresAt: DateTime
    }) => Promise<void>
  }) {
    // date time now plus 5 minutes
    const { popDialog } = useDialogs()
    const defaultExpiresAt = expiresAt || DateTime.now().plus({ minutes: 5 })
    const defaultAssignedAt = assignedAt || DateTime.now()
    const { t } = useTranslation()
    const schema = useMemo(
      () => getSchema(t, hasBeenScheduled ? expiresAt : undefined),
      [t, hasBeenScheduled, expiresAt]
    )
    type FormValues = z.infer<typeof schema>

    const {
      control,
      formState: { isSubmitting },
      setError,
      handleSubmit,
    } = useForm<FormValues>({
      resolver: zodResolver(schema),
      defaultValues: {
        assignedAt: defaultAssignedAt,
        expiresAt: defaultExpiresAt,
      },
    })

    const onSubmit = useCallback(
      async ({ assignedAt, expiresAt }: FormValues) => {
        const expiresAtMinusOneDay = expiresAt.minus({ days: 1 })
        if (assignedAt >= expiresAtMinusOneDay) {
          setError('assignedAt', {
            message: t('instructor_assignment.start_date_after_deadline'),
          })
          return
        }

        try {
          await onSave({
            assignedAt,
            expiresAt,
          })
        } catch (e) {
          toast.error('Schedule Failure')
          throw e
        } finally {
          popDialog()
        }
      },
      [onSave, popDialog, setError, t]
    )

    const currentExpiresAt = useWatch({
      control,
      name: 'expiresAt',
    }) as DateTime<boolean> | null
    const now = DateTime.now()

    return (
      <Dialog
        size="md"
        innerClassName="flex flex-col"
        ignoreBackdropClick
        ignoreEscapeKey
      >
        <DialogCloseButton />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex h-full flex-grow flex-col gap-3 rounded-lg"
          data-testid="schedule-assignment-form"
        >
          <h2 className="text-headline-large">
            {t('instructor_assignment.experience_settings')}
          </h2>
          <BreakoutTooltip
            enabled={hasBeenScheduled}
            content={t('instructor_assignment.disabled_because_its_scheduled')}
          >
            <Controller
              control={control}
              name="assignedAt"
              render={({ field, fieldState }) => (
                <BreakoutDateTimeInput
                  {...field}
                  error={fieldState.error}
                  value={field.value}
                  kind="secondary"
                  label={t('instructor_assignment.start_date')}
                  max={currentExpiresAt?.minus({ days: 1 })}
                  disabled={isSubmitting || hasBeenScheduled}
                  name="assignedAt"
                  data-testid="datetime-input-assignedAt"
                />
              )}
            />
          </BreakoutTooltip>
          <Controller
            control={control}
            name="expiresAt"
            render={({ field, fieldState }) => (
              <BreakoutDateTimeInput
                {...field}
                error={fieldState.error}
                value={field.value}
                kind="secondary"
                label={t('instructor_assignment.deadline')}
                min={now.plus({ minutes: 1 })}
                max={now.plus({ years: 1 })}
                disabled={isSubmitting}
                name="expiresAt"
                data-testid="datetime-input-expiresAt"
              />
            )}
          />
          <BreakoutButton
            type="submit"
            size="large"
            className="mt-auto w-full"
            data-testid="submit-button"
          >
            {t('instructor_assignment.assign_to_class')}
          </BreakoutButton>
        </form>
      </Dialog>
    )
  }
)
