import classNames from 'classnames'
import type { RouteType } from 'config/routes'
import { AnimatePresence, m } from 'framer-motion'
import { useRootStore } from 'hooks/rootStore'
import { reaction } from 'mobx'
import type { QueryParams } from 'mobx-router'
import type { DOMAttributes } from 'react'
import React, { Children, useEffect, useMemo, useState } from 'react'

export function MainPane({
  children,
  className,
  testId,
}: {
  children: React.ReactNode
  className?: string
  testId?: string
}) {
  return (
    <div
      data-testid={testId}
      className={[
        'box-border flex-grow rounded-3xl bg-core-tertiary p-5 pb-8 md:h-full md:p-8 md:px-10',
        className,
      ].join(' ')}
    >
      <div className="items-left flex h-full flex-col align-middle">
        {children}
      </div>
    </div>
  )
}

export function Header(
  props: React.PropsWithChildren<DOMAttributes<HTMLDivElement>> & {
    className?: string
  }
) {
  return (
    <div
      className="flex flex-grow-0 flex-row items-center justify-between whitespace-nowrap"
      {...props}
    >
      {props.children}
    </div>
  )
}

export function Headline(
  props: React.PropsWithChildren<DOMAttributes<HTMLHeadingElement>> & {
    className?: string
  }
) {
  const firstString = Children.toArray(props.children).filter(
    (child) => typeof child === 'string' && child !== '< '
  )[0] as string | undefined

  return (
    <h1
      className="text-display-medium font-display tracking-text-display-medium ml-[-1px] mt-[0.5px] truncate pr-1 text-core-on-tertiary antialiased"
      title={firstString ?? ''}
      aria-live="polite"
      {...props}
    >
      {props.children}
    </h1>
  )
}

export function Actions(
  props: React.PropsWithChildren<
    DOMAttributes<HTMLDivElement> & { className?: string }
  >
) {
  return (
    <div
      className={classNames(
        'flex flex-row flex-wrap justify-end gap-4',
        props.className
      )}
    >
      {props.children}
    </div>
  )
}

export function Contents({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) {
  return (
    <div className={['mt-[19.5px]', className].join(' ')}> {children} </div>
  )
}

const variants = {
  enter: (mode: string) => {
    if (mode === 'slideIn') {
      return {
        opacity: 1,
        x: '200%',
      }
    }
    if (mode === 'slideOut') {
      return {
        opacity: 0,
        x: '0',
      }
    }
    return {
      x: '0',
      opacity: 0,
    }
  },
  animate: (mode: string) => {
    if (mode === 'slideIn') {
      return {
        opacity: 1,
        x: '0',
      }
    }
    if (mode === 'slideOut') {
      return {
        opacity: 1,
        x: '0',
      }
    }
    return {
      x: 0,
      opacity: 1,
    }
  },
  exit: (mode: string) => {
    if (mode === 'slideOut') {
      return {
        opacity: 1,
        x: '200%',
      }
    }
    return {
      opacity: 0,
    }
  },
}

export type AnimationController = {
  key: string | null
  mode: string
  component: JSX.Element | null
}

export type CalculateControllerFunction = (
  params: QueryParams,
  route: RouteType | undefined,
  currentController?: AnimationController
) => AnimationController

const transitionConfig = {
  // duration: 1,
  duration: 0.3,
}
export function TwoColumnAnimatedPane({
  calculateController,
}: {
  calculateController: CalculateControllerFunction
}) {
  const store = useRootStore()

  const initialController = useMemo(() => {
    const params = store.router.params
    const route = store.router.currentRoute
    return calculateController(params, route)
  }, [store, calculateController])

  const [controller, setController] =
    useState<AnimationController>(initialController)

  useEffect(() => {
    return reaction(
      () => ({ params: store.router.params, route: store.router.currentRoute }),
      ({ params, route }) => {
        const newController = calculateController(params, route, controller)
        if (controller.key === newController.key) return
        setController(newController)
      },
      { fireImmediately: true }
    )
  }, [store, controller, calculateController])

  return (
    <div className="relative h-full">
      <AnimatePresence mode="sync" initial={false} custom={controller.mode}>
        {controller.component && (
          <m.main
            variants={variants}
            custom={controller.mode}
            key={controller.key}
            className="absolute inset-0"
            initial="enter"
            animate="animate"
            exit="exit"
            transition={transitionConfig}
          >
            {controller.component}
          </m.main>
        )}
      </AnimatePresence>
    </div>
  )
}
