import type { SlideDeck } from '@breakoutlearning/firebase-repository/models/SlideDeck'
import type { SlideDeckAuthor } from '@breakoutlearning/firebase-repository/models/SlideDeckAuthor'
import type { SlideDeckMaterial } from '@breakoutlearning/firebase-repository/models/SlideDeckMaterial'
import type { SlideDeckReference } from '@breakoutlearning/firebase-repository/models/SlideDeckReference'
import {
  SlideDeckMaterialType,
  SlideDeckReferenceType,
} from '@breakoutlearning/firebase-repository/types'
import classNames from 'classnames'
import { GoogleStorageImage } from 'components/GoogleStorageImage'
import { VideoPreviewDialog } from 'components/VideoPreviewDialog'
import { TriangleRightFillIcon } from 'components/icons/TriangleRightFIll'
import { useDialogs } from 'hooks/dialogs'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

function NoThumbnail({ squareThumbnail }: { squareThumbnail?: boolean }) {
  return (
    <div
      className={classNames(
        'flex flex-row items-center justify-center overflow-hidden rounded-xl bg-surface',
        squareThumbnail
          ? 'text-body-small h-[159px] w-[159px]'
          : 'text-body-large aspect-video max-h-[500px] w-full'
      )}
    ></div>
  )
}

export const RevampedSlideDeckTrailerThumbnail = observer(
  function SlideDeckTrailerThumbnail({
    slideDeckMaterials,
    squareThumbnail = true,
  }: {
    slideDeckMaterials: SlideDeckMaterial[]
    squareThumbnail?: boolean
  }) {
    const { showDialog } = useDialogs()

    if (!slideDeckMaterials?.length)
      return <NoThumbnail squareThumbnail={squareThumbnail} />

    const thumbnail = slideDeckMaterials.find((material) => {
      return material.data.materialType === SlideDeckMaterialType.featuredLarge
    })

    const trailer = slideDeckMaterials.find(
      (material) =>
        material.data.materialType === SlideDeckMaterialType.mp4 &&
        // we need a better way to identify trailers
        material.data.materialName?.toLocaleLowerCase() === 'trailer'
    )

    const hasTrailer = !!trailer?.data.materialLink

    return (
      <div
        className={classNames(
          'relative select-none overflow-hidden ',
          squareThumbnail
            ? 'h-[159px] w-[159px] rounded-xl'
            : 'flex max-h-[500px] items-center rounded-3xl'
        )}
        onClick={(e) => {
          const materialLink = trailer?.data.materialLink
          if (!materialLink) return

          e.preventDefault()
          e.stopPropagation()

          showDialog(() => (
            <VideoPreviewDialog
              src={materialLink}
              inline={false}
              requiresAuthHeaders={!trailer.data.viewableByPublic}
            />
          ))
        }}
      >
        {hasTrailer && (
          <div className="absolute inset-0 flex items-center justify-center rounded-xl">
            <div
              className={classNames(
                'absolute flex cursor-pointer items-center justify-center rounded-full border-[4px] border-fixed-accent-color bg-black bg-opacity-50 p-1',
                {
                  'h-[70px] w-[70px]': !squareThumbnail,
                  'h-[37px] w-[37px]': squareThumbnail,
                }
              )}
            >
              <TriangleRightFillIcon
                size={50}
                className="fill-white stroke-transparent"
              />
            </div>
          </div>
        )}
        {thumbnail ? (
          <GoogleStorageImage
            namespace="slideDeckMaterial"
            src={thumbnail.data.materialLink}
            alt={thumbnail.data.materialName}
            className={classNames(
              'h-full w-full overflow-hidden  object-cover',
              {
                'object-center': !squareThumbnail,
              }
            )}
          />
        ) : (
          <NoThumbnail squareThumbnail={squareThumbnail} />
        )}
      </div>
    )
  }
)

export const RevampedSlideDeckCard = observer(function SlideDeckView({
  slideDeck,
  slideDeckMaterials,
  slideDeckAuthors,
  references,
  onClick,
}: {
  slideDeck: SlideDeck
  references: SlideDeckReference[]
  slideDeckMaterials: SlideDeckMaterial[]
  slideDeckAuthors?: SlideDeckAuthor[]
  onClick: () => void
}) {
  const author = slideDeckAuthors?.[0]
  const authorName = author?.data.authorName || ''
  const { t } = useTranslation()

  const alternativeTo = references?.[0]

  return (
    <div
      onClick={onClick}
      data-testid={`slide-deck-card-${slideDeck.id}`}
      className="flex cursor-pointer flex-row gap-5 rounded-2.5xl  border-3 border-surface-bright bg-surface-bright p-3 hover:border-outline-variant"
    >
      <div className="h-[159px] w-[159px]">
        <RevampedSlideDeckTrailerThumbnail
          slideDeckMaterials={slideDeckMaterials}
        />
      </div>
      <div className="grid flex-1 grid-cols-2">
        <div>
          <img
            src={slideDeck.data.slideDeckImageURL}
            className="h-9 p-1"
            alt={slideDeck.data.slideDeckName}
          />
          <div className="text-title-large text-on-surface">
            {slideDeck.data.slideDeckName}
          </div>
          <div className="text-label-medium text-on-surface">
            {slideDeck.data.slideDeckTeaser}
          </div>
          <div className="text-body-medium text-on-surface">
            {authorName
              ? t('instructor_library.by_author', { author: authorName })
              : ''}
          </div>
          <div className="text-body-medium mt-2 text-on-surface-var">
            <ul className="list-disc pl-4">
              {slideDeck.data.slideDeckKeyConcepts.map((line) => (
                <li key={line}>{line}</li>
              ))}
            </ul>
          </div>
        </div>
        {alternativeTo && (
          <div className="flex flex-col justify-center">
            <div
              className={classNames(
                'min-h-[30%] border-l-2 border-fixed-accent-color pl-8',
                alternativeTo.data.referenceType ===
                  SlideDeckReferenceType.alternativeTo
                  ? 'border-fixed-accent-color'
                  : 'border-on-surface-disabled'
              )}
            >
              <div className="text-label-medium mb-2 text-on-surface">
                {alternativeTo.data.referenceType ===
                SlideDeckReferenceType.alternativeTo
                  ? t('instructor_library.alternative_to')
                  : t('instructor_library.based_on')}
              </div>
              <div className="text-title-small italic text-on-surface">
                {alternativeTo.data.referenceTitle}
              </div>
              <div className="text-body-medium text-on-surface-var">
                {alternativeTo.data.referenceAuthor},{' '}
                {alternativeTo.data.referencePublisher},{' '}
                {alternativeTo.getFormattedPublishedAt()}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
})
