import { Spinner } from 'components/Spinner'
import { observer } from 'mobx-react-lite'
import { CaseListItem } from './CaseListItem'
import { useInstructorSectionCubit } from 'hooks/cubits/instructorSection'
import { useTranslation } from 'react-i18next'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { Search } from 'components/icons/Search'
import { useRootStore } from 'hooks/rootStore'
import { useEffect, useMemo, useRef, useState } from 'react'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import { BreakoutCard } from 'components/design-system/BreakoutCard'

export const CasesView = observer(function CasesView() {
  const cubit = useInstructorSectionCubit()

  if (cubit.section.isLoading) {
    return (
      <div className="flex w-full flex-col items-center justify-center md:h-full">
        <Spinner />
      </div>
    )
  }

  if (cubit.sectionAssignmentsSorted.length) {
    return (
      // <div className="rounded-2x flex flex-row flex-wrap gap-5 pb-5 md:h-full md:pb-0">
      <BreakoutCard.Grid>
        {cubit.sectionAssignmentsSorted.map((assignment) => (
          <CaseListItem key={assignment.id} assignment={assignment} />
        ))}
      </BreakoutCard.Grid>
      //  </div>
    )
  }
  return <EmptyView />
})

const EmptyView = observer(function EmptyView() {
  const { t } = useTranslation()
  const store = useRootStore()
  const cubit = useInstructorSectionCubit()
  const logoContainerRef = useRef<HTMLDivElement>(null)

  const [logoLayout, setLogoLayout] = useState({ rows: 0, columns: 0 })

  useEffect(() => {
    if (!logoContainerRef.current) return
    const { current } = logoContainerRef
    const observer = new ResizeObserver(() => {
      const { height, width } = current.getBoundingClientRect()
      const availableHeight = height - 64
      const availableWidth = width * 0.7
      const runSpacing = 13
      const spacing = 9
      const columns = Math.ceil(availableWidth / (spacing + 75))
      const rows = Math.floor(availableHeight / (runSpacing + 75))
      setLogoLayout({ rows, columns })
    })
    observer.observe(logoContainerRef.current)
    return () => observer.unobserve(current)
  }, [])

  const slideDeckRows = useMemo(() => {
    // filter out slide decks without images keeping only one per type
    const slideDecks = (() => {
      const typesAdded = new Set()
      const slideDecksWithImage = []
      for (const slideDeck of cubit.slideDecksById.values()) {
        if (
          slideDeck.data.slideDeckImageURL &&
          !typesAdded.has(slideDeck.data.slideDeckTypeId)
        ) {
          slideDecksWithImage.push(slideDeck)
          typesAdded.add(slideDeck.data.slideDeckTypeId)
        }
      }
      return slideDecksWithImage
    })()
    // arrange into 2d array of rows and columns
    const slideDeckRows = []
    let index = 0
    for (let i = 0; i < logoLayout.rows; i++) {
      const row = []
      for (let j = 0; j < logoLayout.columns; j++) {
        if (index < slideDecks.length) {
          row.push(slideDecks[index])
          index++
        } else {
          break
        }
      }
      slideDeckRows.push(row)
      if (index >= slideDecks.length) {
        break
      }
    }
    return slideDeckRows
    // if i don't call size here it will not update the component when the size changes
    // there has to be a better way to do this..
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cubit.slideDecksById, cubit.slideDecksById.size, logoLayout])

  return (
    <div className="flex h-full w-full flex-col items-center justify-center rounded-3xl bg-light-grey px-10 pt-8">
      <div className="flex flex-1" />
      <div className="flex h-[202px] flex-col items-center justify-end text-center">
        <h2 className="text-headline-large mb-3">
          {t('instructor_class.no_cases')}
        </h2>
        <span className="text-body-medium mb-6 line-clamp-2 max-w-[320px]">
          {t('instructor_class.no_cases_description')}
        </span>
        <BreakoutButton
          kind="primary"
          size="large"
          data-testid="explore-catalog-button"
          icon={<Search size={14} />}
          onClick={() => {
            const readOnly =
              cubit.section.data.shareable &&
              cubit.section.data.instructorUserId !==
                cubit.repository.currentUser?.uid
            const params = !readOnly ? { sectionId: cubit.section.id } : {}
            store.navigateTo('instructorLibrary', undefined, params)
          }}
        >
          {t('instructor_class.explore_catalog')}
        </BreakoutButton>
      </div>
      <div
        ref={logoContainerRef}
        className="flex w-full flex-1 flex-col items-center justify-start gap-[13px] gap-x-[9px] gap-y-[13px] overflow-hidden py-8"
      >
        {slideDeckRows.map((row, index) => {
          return (
            <div
              key={index}
              className="flex flex-row items-center justify-center gap-[9px]"
            >
              {row.map(
                ({ data: { slideDeckName, slideDeckImageURL } }, index2) => {
                  return (
                    <BreakoutTooltip
                      content={slideDeckName}
                      key={[slideDeckName, index, index2].join('-')}
                    >
                      <div className="h-[75px] w-[75px] rounded-xl bg-core-tertiary">
                        <img
                          className="h-full w-full object-scale-down"
                          src={slideDeckImageURL}
                        />
                      </div>
                    </BreakoutTooltip>
                  )
                }
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
})
